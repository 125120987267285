import gql from 'graphql-tag'

export const GET_UNIT_SESSION_PRICE = gql`
    query DisplayUnitSessionPriceDisplay {
        fetchUnitSessionPrice {
            success
            unitSessionPrice
            error
        }
    }
`

export const GET_SESSIONS_LIST = gql`
  query DisplaySessionTypeListOutput($email: String!) {
    fetchSessionTypeList(email: $email){
      success
      sessionType {
        id
        title
        length
        charge
        user {
          id
          username
          firstName
          lastName
          email
          profileImg
          phoneNumber
        }
      }
      error
      unitSessionPrice
    }
  }
`

export const CREATE_SESSION_TYPE = gql`
  mutation CreateSessionTypeMutation($sessionData: SessionTypeInput!) {
    createSessionType(sessionData: $sessionData) {
      success
      session {
        id
        title
        length
        charge
        user {
          id
          username
          firstName
          lastName
          email
          profileImg
          phoneNumber
        }
      }
      error
    }
  }
`

export const UPDATE_SESSION_TYPE = gql`
  mutation EditSessionTypeMutation($sessionData: EditSessionTypeInput!) {
    editSessionType(sessionData: $sessionData) {
      success
      error
    }
  }
`

export const DELETE_SESSION_TYPE = gql`
  mutation DeleteSessionTypeMutation($sessionData: DeleteSessionTypeInput!) {
    deleteSessionType(sessionData: $sessionData) {
      success
      error
    }
  }
`

export const MARK_SESSION_COMPLETED = gql`
  mutation MarkSessionCompletedMutation($sessionData: MarkCompleteSessionInput!) {
    markSessionCompleted(sessionData: $sessionData) {
      success
      error
    }
  }
`