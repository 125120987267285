import gql from 'graphql-tag'

export const GET_VIDEO_AUTH_TOKEN = gql`
  mutation VideoAuthTokenMutation($authData: VideoAuthInput!) {
    generateVideoAuth(authData: $authData){
      success
      videotoken
      texttoken
      session {
        id
        sessionType{
          id
          title
          length
          charge
        }
        date
        startTime
        endTime
        videochannel
        textchannel
      }
      error
    }
  }
`

export const GET_VIDEO_DEMO_TOKEN = gql`
  mutation VideoDemoTokenMutation($authData: VideoDemoInput!) {
    generateVideoDemo(authData: $authData){
      success
      videotoken
      texttoken
      error
    }
  }
`
