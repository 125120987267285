<template>
  <div class="text-center">
    <v-card 
      height="400"
      width="320"
      class="mx-auto overflow-y-hidden position-relative" 
      rounded
      flat
    >
      <v-toolbar flat>
        <v-toolbar-title class="text-center black--text">
          <v-btn
            text
            class="text-h5 px-0"
            left
            :ripple="false"
          >
            <img
              src="@/assets/images/icons/live_chat.svg"
              height="25"
              width="25"
              class="pr-1"
            > {{ $t("live_chat") }}
          </v-btn>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="py-0 my-0">
        <v-divider class="blue_spindle" />
      </v-card-text>
      <v-card-text class="pa-0 ma-0 overflow-y">
        <div id="chatbox">
          <div id="chatmessages">
            <v-card
              v-for="(item, index) in messages"
              :key="index"
              :color="rtm.accountName === item.user ? 'grey_regular' : null"
              class="ma-3"
              width="70%"
              outlined
              rounded
              :class="rtm.accountName !== item.user ? 'float-left' : 'float-right'"
            >
              <v-img 
                v-if="item.type === 'IMAGE'" 
                :src="item.source"
                class="text-left mb-1 pointer"
                @click="download(item.source, item.fileName)"
              />
              <v-card-text 
                v-if="item.type === 'FILE'" 
                class="text-left mb-1 pointer" 
                @click="download(item.source, item.fileName)"
              >
                <v-icon large>
                  mdi-file-document
                </v-icon>
                {{ item.fileName }}
              </v-card-text>
              <v-card-text class="text-h6 py-2 text-left">
                {{ item.text }}
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="message-input-block">
        <input
          ref="file"
          hidden
          type="file"
          @change="handleFileImageUpload()"
        >
        <v-text-field
          v-model="message"
          hide-details="auto"
          outlined
          height="50"
          class="pt-0 message-input"
          placeholder="Type your message"
          @keyup.enter="sendMessage"
        >
          <template #prepend-inner>
            <v-card
              flat
              :ripple="false"
              color="grey"
              @click="$refs.file.click()"
            >
              <img
                height="25"
                width="25"
                class="mr-2"
                src="/images/icon/attachment.png"
              >
            </v-card>
            <v-divider 
              vertical
              class="blue_spindle"
            />
          </template>
          <template #append>
            <v-btn
              depressed 
              text
              class="ma-0 pb-6 pr-0 primary--text font-weight-medium"
              :ripple="false"
              @click="sendMessage"
            >
              {{ $t("send") }}
            </v-btn>
          </template>
        </v-text-field>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import RTMClient from "@/agora/chat/rtmClient";
import { createBlobObject, fileOrBlobToDataURL, isImage } from "@/agora/chat/common";
import { mapGetters } from "vuex";
import FileSaver from 'file-saver';

export default {
  name: "AlertBox",
  props: {
    chatDialog: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        appid = '5042fbb7676e479c9f81f553b8b59620',
        token = null,
        uid   = null,
        channel    = null,
        userName   = null,
        msgChannel = null
      },
    },
  },
  data() {
    return {
      message: null,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters([ "messages" ]),
  },
  mounted(){
    this.scrollToBottom()
  },
  created() {
    this.dialog = this.chatDialog;
    this.rtm = new RTMClient();
    let { rtm } = this;

    rtm.on("ConnectionStateChanged", (newState, reason) => {
      console.log("reason", reason);
    });

    rtm.on("MemberJoined", async({ channelName, args }) => {
      const memberId = args[0];
      // this.messages.push({
      //   text: `${memberId} join channel`,
      //   user: memberId,
      //   type: "info",
      // });
      console.log("channel ", channelName, " member: ", memberId, " joined");
    });

    rtm.on("MemberLeft", async({ channelName, args }) => {
      const memberId = args[0];
      // this.messages.push({
      //   text: `${memberId} leave channel`,
      //   user: memberId,
      //   type: "info",
      // });
      console.log("channel ", channelName, " member: ", memberId, " joined");
    });

    rtm.on("ChannelMessage", async({ channelName, args }) => {
      const [ message, memberId ] = args;

      if(message.messageType === 'TEXT'){
        this.$store.commit("setChatMessages", {
          text: message.text,
          user: memberId,
          type: message.messageType,
        });
        this.scrollToBottom()
      }else {
        var reader = new FileReader();
        const blob = await this.rtm.downloadImage(message)
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.$store.commit("setChatMessages", {
            text: message.description,
            source: reader.result,
            user: memberId,
            type: message.messageType,
            fileName: message.fileName,
          });
          this.scrollToBottom()
        }
      }

      console.log(
        "channel ",
        channelName,
        ", messsage: ",
        message.text,
        ", memberId: ",
        memberId,
      );
    });
    this.login();
  },
  methods: {
    alert(message, isError) {
      this.$emit("showAlert", { message, isError });
    },
    login() {
      if (this.rtm._logined) {
        this.showAlert({ message: "You already logined", isError: true });
        return;
      }

      try {
        const { channel, token, appid } = this.options;
        this.rtm.init(appid);
        window.rtm = this.rtm;
        this.rtm
          .login(channel, token)
          .then(() => {
            console.log("login");
            this.rtm._logined = true;
            this.joinChannel()
            this.alert(`Login: ${this.options.userName}`);
          })
          .catch(err => {
            // eslint-disable-line
            console.log(err);
          });
      } catch (err) {
        this.alert("Login failed, please open console see more details", true);
        console.error(err);
      }
    },
    sendMessage() {
      if (this.message) {
        if (!this.isLogedIn()) {
          return;
        }
        this.rtm
          .sendChannelMessage(this.message, this.options.msgChannel)
          .then(() => {
            this.$store.commit("setChatMessages", {
              text: this.message,
              user: this.options.userName,
              type: "msg",
            });
            this.scrollToBottom()
            this.message = null
            console.log("Message send successfully");
          })
          .catch(err => {
            this.alert(
              `Send message to channel ${this.options.userName} failed, please open console see more details.`,
              true,
            );
            console.error(err);
          });
      }
    },
    leaveChannel() {
      if (!this.isJoined(true)) {
        this.rtm._logined = false;
        return;
      }

      this.rtm
        .leaveChannel(this.options.msgChannel)
        .then(() => {
          if (this.rtm.channels[this.options.msgChannel]) {
            this.rtm.channels[this.options.msgChannel].joined = false;
            this.rtm.channels[this.options.msgChannel] = null;
            this.rtm._logined = false;
            // this.messages.push({
            //   text: `${this.options.userName} leave channel`,
            //   user: this.options.userName,
            //   type: "info",
            // });
            console.log("Leave channel successfully");
          }
        })
        .catch(err => {
          this.alert(
            "Leave channel failed, please open console see more details.",
            true,
          );
          console.error(err);
        })
        .finally(() => {
          this.dialog = false;
        });
    },

    isLogedIn() {
      let isLogedIn = true;
      if (!this.rtm._logined) {
        this.alert("Please Login First", true);
        isLogedIn = false;
      }
      return isLogedIn;
    },

    isJoined(isLeave = false) {
      let isJoined = false;
      if (
        this.rtm.channels[this.options.msgChannel] ||
        (this.rtmInstance.channels[this.options.msgChannel] &&
          this.rtm.channels[this.options.msgChannel].joined)
      ) {
        // if (isLeave) {
        //   this.alert("You already leave", true);
        // }
        isJoined = true;
      }
      return isJoined;
    },
    joinChannel() {
      this.rtm
        .joinChannel(this.options.msgChannel)
        .then(() => {
          this.rtm.channels[this.options.msgChannel].joined = true;
          // this.messages.push({
          //   text: `${this.options.userName} join channel`,
          //   user: this.options.userName,
          //   type: "info",
          // });
        })
        .catch(err => {
          this.alert(
            "Join channel failed, please open console see more details.",
            true,
          );
          console.error(err);
        });
    },
    close(){
      this.$emit('close')
    },

    scrollToBottom() {
      this.$emit('scroll-bottom')
    },
    handleFileImageUpload(){
      var fileObject = this.$refs.file.files[0];      
      this.$refs.file.value = null;

      var re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png|\.pdf|\.mp4|\.mp3|\.docx|\.doc|\.ppt|\.avi|\.mov|\.xls|\.xlsx)$/i;
      if (!re.exec(fileObject.name)) {
        alert("File extension not supported!");
      }

      fileOrBlobToDataURL(fileObject, async res => {
        const blob = await createBlobObject(res);
        // var desc = isImage(fileObject.name) ? 'Image message' : 'File message';
        var messageType = isImage(fileObject.name) ? 'IMAGE' : 'FILE';
        this.rtm.sendChannelMediaMessage({
          blob,
          fileName: fileObject.name,
          // description: desc,
          type: messageType,
        }, this.options.msgChannel)
          .then(() => {
            this.$store.commit("setChatMessages", {
              // text: desc,
              user: this.options.userName,
              type: messageType,
              source: res,
              fileName: fileObject.name,
            });
            this.scrollToBottom()
            console.log("Message send successfully");
          })
          .catch(err => {
            this.alert(
              `Send message to channel ${this.options.userName} failed, please open console see more details.`,
              true,
            );
            console.error(err);
          });
      })
    },
    download(blob, name){
      FileSaver.saveAs(blob, name);
    },
  },
};
</script>

<style lang="scss" scoped>
  #chatbox {
    overflow:   scroll;
    position:   relative;
    width:      100%;
    height:260px; 
    overflow-x: hidden;
    overflow-y: auto;
  }
  #chatmessages{
    overflow:   auto;
    position:   absolute;
    bottom:     0;
    top:10px;
    width: 100%;
    max-height:260px; 
    overflow-x: hidden;
    overflow-y: auto;
  }
  .message-input-block {
    position:   absolute;
    bottom: 0;
   
  }
</style>
<style lang="scss">
  .message-input.v-text-field  {
    fieldset {
      background-color:transparent !important;
    }
    background-color:$light-grey !important;
  }
  .pointer {
    cursor: pointer;
  }
</style>
