<template>
  <div
    :id="domId"
    ref="player"
    :class="isClient ? (fullscreen ? 'agora-fullwidth' : 'agora-video-client-player') : (fullscreen ? 'agora-fullwidth' : 'agora-video-host-player')"
  />
</template>

<script>
export default {
  name: 'StreamPlayer',
  props: {
    stream: {
      type: Object,
      default: () => {},
    },
    domId: {
      type: Number,
      default: null,
    },
    isClient: {
      type: Boolean,
      default: true,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$nextTick(function() {
      if (this.stream && !this.stream.isPlaying()) {
        this.stream.play(`${this.domId}`, {fit: 'cover'}, err => {
          if (err && err.status !== 'aborted') {
            console.warn('trigger autoplay policy')
          }
        })
      }
    })
  },
  beforeDestroy() {
    if (this.stream) {
      if (this.stream.isPlaying()) {
        this.stream.stop()
      }
      this.stream.close()
    }
  },
}
</script>

<style>
.agora-video-client-player {
  height: 100px;
  width: 100px;
  overflow: hidden;
}
.agora-video-host-player {
  height: 67vh;
  width: 100%;
}
.agora-fullwidth {
  height: 100vh;
}
</style>