import AgoraRTM from 'agora-rtm-sdk'
import EventEmitter from 'events'

export default class RTMClient extends EventEmitter {
  constructor() {
    super()
    this.channels = {}
    this._logined = false
  }

  /**
   * Init AgoraRTM Instance
   * @param {*} appId 
   */
  init(appId) {
    this.client = AgoraRTM.createInstance(appId)
    this.subscribeClientEvents()
  }

  /**
   * Cubscribe client events
   */
  subscribeClientEvents() {
    const clientEvents = [
      'ConnectionStateChanged',
      'MessageFromPeer',
    ]
    clientEvents.forEach(eventName => {
      this.client.on(eventName, (...args) => {
        console.log('emit ', eventName, ...args)
        // log event message
        this.emit(eventName, ...args)
      })
    })
  }

  /**
   * Subscribe channel events
   * @param {*} channelName 
   */
  subscribeChannelEvents(channelName) {
    const channelEvents = [
      'ChannelMessage',
      'MemberJoined',
      'MemberLeft',
    ]
    channelEvents.forEach(eventName => {
      this.channels[channelName].channel.on(eventName, (...args) => {
        console.log('emit ', eventName, args)
        this.emit(eventName, { channelName, args: args })
      })
    })
  }

  /**
   * Login to account
   * @param {*} accountName 
   * @param {*} token 
   * @returns 
   */
  async login(accountName, token) {
    this.accountName = accountName
    return this.client.login({ uid: this.accountName, token })
  }

  /**
   * Login from account
   * @returns 
   */
  async logout() {
    return this.client.logout()
  }

  /**
   * Join message channel
   * @param {*} name 
   * @returns 
   */
  async joinChannel(name) {
    console.log('joinChannel', name)
    const channel = this.client.createChannel(name)
    this.channels[name] = {
      channel,
      joined: false, // channel state
    }
    this.subscribeChannelEvents(name)
    return channel.join()
  }

  /**
   * Leave message channel
   * @param {*} name 
   * @returns 
   */
  async leaveChannel(name) {
    console.log('leaveChannel', name)
    if (!this.channels[name] ||
      (this.channels[name] &&
        !this.channels[name].joined)) return
    return this.channels[name].channel.leave()
  }

  /**
   * Send message to channel
   * @param {*} text 
   * @param {*} channelName 
   * @returns 
   */
  async sendChannelMessage(text, channelName) {
    if (!this.channels[channelName] || !this.channels[channelName].joined) return
    return this.channels[channelName].channel.sendMessage({ text, messageType: 'TEXT' })
  }

  /**
   * Send media message to channel
   * @param {*} blob 
   * @param {*} channelName 
   * @returns 
   */
  async sendChannelMediaMessage(data, channelName) {
    const { blob, fileName, description, type } = data;
    if (!this.channels[channelName] || !this.channels[channelName].joined) return
    const messageOptions = {
      messageType: type,
      fileName: fileName,
      description: description,
    }

    if(type === 'IMAGE'){
      messageOptions.thumbnail = blob;
      messageOptions.width = 100;
      messageOptions.height = 200;
      messageOptions.thumbnailWidth = 50;
      messageOptions.thumbnailHeight = 200; 
    }
    const mediaMessage = await this.client.createMediaMessageByUploading(blob, messageOptions)

    const imageMessage = await this.client.createMessage({
      mediaId: mediaMessage.mediaId, // Your mediaId
      ...messageOptions,
    })
    return this.channels[channelName].channel.sendMessage(mediaMessage)
  }
  
  async downloadImage(message) {
    return await this.client.downloadMedia(message.mediaId)
  }

  async cancelImage(message) {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), 1000)
    await this.client.downloadMedia(message.mediaId, {
      cancelSignal: controller.signal,
      onOperationProgress: ({currentSize, totalSize}) => {
        console.log(currentSize, totalSize)
      },
    })
  }
}